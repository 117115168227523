import useStyles from "./Projects.style";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import useProjects from "./projects";
import Paper from "@mui/material/Paper";

const Project = () => {
  const classes = useStyles();
  const projects = useProjects();

  return (
    <div className={classes.container}>
      <Container maxWidth="lg">
        <div className={classes.title}>
          <Typography
            variant="h3"
            color="textPrimary"
            fontWeight="fontWeightBold"
            id="Projects"
          >
            <Box sx={{ paddingTop: 5, textAlign: "center" }}>My Projects</Box>
          </Typography>
        </div>

        <Grid container spacing={4} marginTop={3}>
          {projects.map((project) => (
            <Grid key={project.id} xs={12} sm={6} md={4}>
              <Paper elevation={10} sx={{ height: "100%" }}>
                <Card className={classes.card}>
                  <CardActionArea
                    href={project.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CardMedia
                      className={classes.cardMedia}
                      image={project.src}
                      title={project.alt}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5">
                        {project.title}
                      </Typography>
                      <Typography>{project.description}</Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ overflow: "auto" }}
                    >
                      {project.tools.map((tool, i) => (
                        <Chip
                          key={`${i}-${tool}`}
                          label={`${tool}`}
                          variant="outlined"
                        />
                      ))}
                    </Stack>
                  </CardActions>
                </Card>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Project;
