import { makeStyles } from "@mui/styles";
import backgroundImage from "./../images/hero-image.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "950px",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    margin: "0px",
    padding: "0px",
    border: "0px",

    [theme.breakpoints.up("sm")]: {
      height: "950px !important",
      //   height: '900px !important',
    },
    // [theme.breakpoints.up('md')]: {
    //   height: '950px !important',
    //   //   height: '900px !important',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   height: '100vh !important',
    //   //   height: '900px !important',
    // },
  },
  profileName: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "3.0rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.5rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "4.5rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "6rem !important",
    },
  },
  firstName: {
    fontFamily: ["Lato", "sans-serif"],
    fontWeight: 900,
    textStroke: "1px #000",
  },
  lastName: {
    fontFamily: ["Lato", "sans-serif"],
    fontWeight: 900,
    textStroke: "1px #000",
    color: "#E3DE77",
  },
  profileSummary: {
    fontFamily: ["Lato", "sans-serif"],
    fontWeight: 400,
    // color: '#fff',
    textStroke: "0.3px #000",
    color: "#fff",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem !important",
    },
  },
  content: {
    position: "relative",
    top: "240px",
  },
  rotateIcon: {
    animation: "$spin 2s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(-360deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  typeWriteSummary: {
    // letterSpacing: '5px',
    overflow: "hidden",
    borderRight: `2px solid ${theme.palette.grey[300]}`,
    whiteSpace: "nowrap",
    margin: "0 auto",
    // animation:
    animation:
      "$typewriter 3s steps(60) 1s 1 normal both, $blinkTextCursor 500ms infinite",
    width: 0,
  },
  "@keyframes typewriter": {
    from: {
      width: 0,
    },
    to: {
      width: "100%",
    },
  },
  "@keyframes blinkTextCursor": {
    from: {
      borderRightColor: theme.palette.grey[300],
    },
    to: {
      borderRightColor: "transparent",
    },
  },
}));

export default useStyles;
