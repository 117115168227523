import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    // background: '#A3A2E3 !important',
    // backgroundColor: 'transparent !important',
    // opacity: '0.7',
  },
  icon: {
    // marginRight: '20px',
    // position: 'absolute',
    // left: '120px',
  },
  menuItems: {
    textTransform: 'none',
    color: '#000',
  },
}))

export default useStyles
