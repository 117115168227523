import { useState, useEffect } from "react";
import Header from "./header/Header";
import Home from "./home/Home";
import About from "./about/About";
import Project from "./projects/Project";
import Contact from "./contact/Contact";
import "./App.css";
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CssBaseline,
  Container,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import useStyles from "./styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ProfilePicture from "./images/profile-picture.svg";

function App() {
  const classes = useStyles();
  const [scrollBeyondThreshold, setScrollBeyondThreshold] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 910) {
        // Adjust the scroll threshold as needed
        setScrollBeyondThreshold(true);
      } else {
        setScrollBeyondThreshold(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <Header scrollBeyondThreshold={scrollBeyondThreshold} />

      <section id="#home">
        <Home />
      </section>

      <section id="#about">
        <About scrollBeyondThreshold={scrollBeyondThreshold} />
      </section>

      <section id="#project">
        <Project />
      </section>

      <section id="#contact">
        <Contact />
      </section>

      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          {/* Footer */}
        </Typography>
        <a
          style={{ textDecoration: "none" }}
          href="https://github.com/badacorp"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="subtitle1" align="center" color="textSecondary">
            Created By{" "}
            <img src={ProfilePicture} width={40} height={40} alt="Basit Bada" />{" "}
            <Box sx={{ display: "inline-block" }}>BadaCorp</Box>
          </Typography>
        </a>
      </footer>
    </>
  );
}

export default App;
