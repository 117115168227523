import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "rgba(255, 255, 255, 0.20)",
    height: "auto",
    marginBottom: "20px",
  },
  subtitle: {
    marginTop: "30px",
  },
  content: {
    // marginTop: "30px !important",
    // fontWeight: "bolder !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem !important",
    },
  },
}));

export default useStyles;
