import CatstronautImg from "../images/catstronaut.webp";
import RavenousImg from "../images/ravenous.webp";
import WanderlustImg from "../images/wanderlust.webp";
import CodecademyStoreImg from "../images/codecademy-store.webp";
import ExpenseTrackerImg from "../images/expense-tracker.webp";

const useProjects = () => {
  return [
    {
      id: 0,
      title: "Catsronaut",
      description:
        "A full-stack app for adventurous cats that want to explore the universe.",
      src: CatstronautImg,
      alt: "Catsronaut",
      tools: [
        "Apollo GraphQl",
        "Git",
        "Node.js",
        "React",
        "REST Data source",
        "TypeScript",
      ],
      link: "https://badacorp-catstronauts-client.netlify.app/",
    },
    {
      id: 1,
      title: "Ravenous",
      description:
        "A Yelp like clone that allows users to search for businesses in a particular location.",
      src: RavenousImg,
      alt: "Ravenous",
      tools: ["Git", "React", "SCSS", "TypeScript", "Yelp API"],
      link: "https://ravenous-yelp-like-clone.netlify.app/",
    },
    {
      id: 2,
      title: "Wanderlust",
      description:
        "A travel recommendation website that shows users the three random top attractions and the weather of a place they would like to visit.",
      src: WanderlustImg,
      alt: "Wanderlust",
      tools: [
        "CSS",
        "FourSquare API",
        "Git",
        "HTML",
        "JavaScript",
        "OpenWeather API",
      ],
      link: "https://badacorp.github.io/Wanderlust/",
    },
    {
      id: 3,
      title: "Codecademy Store",
      description: "A replica of Codecademy's online store.",
      src: CodecademyStoreImg,
      alt: "Codecademy Store",
      tools: ["CSS", "Git", "Redux", "React", "TypeScript"],
      link: "https://badacorp-codecademy-store.netlify.app/",
    },
    {
      id: 4,
      title: "Expense Tracker",
      description:
        "A budgeting and expense tracking app that allows a user to set budgets and track transactions.",
      src: ExpenseTrackerImg,
      alt: "Expense Tracker",
      tools: ["CSS", "Git", "Redux", "React", "TypeScript"],
      link: "https://badacorp-expense-tracker.netlify.app/",
    },
  ];
};

export default useProjects;
