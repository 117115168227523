import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  //   container: {
  //     background:
  //       "linear-gradient(180deg, #E9E4EE 11.35%, #81629E 58.23%, #FFF 94.17%)",
  //   },
  //   cardGrid: {
  //     padding: "20px 0",
  //   },
  //   card: {
  //     height: "100%",
  //     display: "flex",
  //     flexDirection: "column",
  //   },
  //   cardMedia: {
  //     paddingTop: "56.25%",
  //   },
  //   cardContent: {
  //     flexGrow: 1,
  //   },
  form: {
    marginTop: "0.3rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    // "& > *": {
    //   margin: "0.5rem 0",
    // },
  },
}));

export default useStyles;
