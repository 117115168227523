import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useStyles from "./Home.style";
import LinkedInLogo from "../images/linkedin-logo.svg";
import GitHubLogo from "../images/github-logo.svg";
import CodeCademyLogo from "../images/codecademy-logo.svg";
import Typewriter from "typewriter-effect";

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <span id="Home"></span>
      <div className={classes.container}>
        <Container maxWidth="md">
          <div className={classes.content}>
            <Typography
              className={classes.profileName}
              variant="h2"
              align="center"
              gutterBottom
            >
              <span className={classes.firstName}>Basit</span>{" "}
              <span className={classes.lastName}>Bada</span>
            </Typography>
            <div style={{ display: "block" }}>
              <Typography
                className={`${classes.profileSummary}`}
                // sx={{ textStroke: "1px black" }}
                variant="h5"
                align="center"
                fontWeight="fontWeightRegular"
                paragraph
              >
                <Typewriter
                  options={{
                    strings: [
                      "Hi, I’m Basit, a Software Developer with 2 years of experience in Programming with TypeScript, React, and Node. I specialize in both frontend and backend web development and have worked on projects ranging from a mobile repair management app to a building and properties assessment app.",
                      "I am passionate about creating innovative solutions that solve real-world problems. My goal is to help businesses and individuals achieve their goals through technology.",
                      "Check out some of my recent projects below and feel free to get in touch if you have any questions or would like to work together.",
                    ],
                    autoStart: true,
                    loop: true,
                    delay: 10,
                    pauseFor: 5000,
                    deleteSpeed: 1,
                  }}
                />
              </Typography>
            </div>

            <div className={classes.icons}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item>
                  <a
                    href="https://www.linkedin.com/in/basitbada/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={LinkedInLogo}
                      alt="LinkedIn"
                      height={36}
                      width={36}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://github.com/badacorp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={GitHubLogo} alt="GitHub" height={36} width={36} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.codecademy.com/profiles/BasitBada"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={classes.rotateIcon}
                      src={CodeCademyLogo}
                      alt="CodeCademy"
                      height={36}
                      width={36}
                    />
                  </a>
                </Grid>
              </Grid>

              <Stack sx={{ pt: 4 }} direction="row" justifyContent="center">
                <Button
                  // style={{ backgroundColor: "#260A49" }}
                  variant="contained"
                  color="secondary"
                  href="https://docs.google.com/document/d/16IJh9UA22z1AV8Muuj5ozTNUExVqOk9jY8H_DTxKpTk/edit?usp=sharing"
                  target="_blank"
                >
                  View Resume
                </Button>
              </Stack>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Home;
