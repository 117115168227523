import { makeStyles } from "@mui/styles";
import backgroundImage from "./images/hero-image.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'transparent',
    // backgroundColor: 'rgba(255, 255, 255, 0.50)',
    // padding: theme.spacing(8, 0, 6),
  },
  icon: {
    marginRight: "20px",
  },
  button: {
    marginTop: "40px",
  },
  cardGrid: {
    padding: "20px 0",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    // position: "fixed",
    left: "0px",
    bottom: "0px",
    backgroundColor: theme.palette.background.paper,
    padding: "50px",
  },
}));

export default useStyles;
