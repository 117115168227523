import DotNetImg from "../images/dot-net-plain-wordmark.svg";
import AzureImg from "../images/azure-original-wordmark.svg";
import BitbucketImg from "../images/bitbucket-original-wordmark.svg";
import BootstrapImg from "../images/bootstrap-plain-wordmark.svg";
import CImg from "../images/c-line.svg";
import CSharpImg from "../images/csharp-line.svg";
import CPlusPlusImg from "../images/cplusplus-line.svg";
import CSS3Img from "../images/css3-plain-wordmark.svg";
import DockerImg from "../images/docker-original-wordmark.svg";
import ExpressImg from "../images/express-original-wordmark.svg";
import GitImg from "../images/git-plain-wordmark.svg";
import GitHubImg from "../images/github-original-wordmark.svg";
import GraphQLImg from "../images/graphql-plain-wordmark.svg";
import JQueryImg from "../images/jquery-original-wordmark.svg";
import JiraImg from "../images/jira-original-wordmark.svg";
import MaterialUIImg from "../images/materialui-original.svg";
import MongoDBImg from "../images/mongodb-original-wordmark.svg";
import MySQLImg from "../images/mysql-plain-wordmark.svg";
import MSSQLServerImg from "../images/microsoftsqlserver-plain-wordmark.svg";
import NodeJSImg from "../images/nodejs-original-wordmark.svg";
import PostgreSQLImg from "../images/postgresql-original-wordmark.svg";
import PythonImg from "../images/python-original-wordmark.svg";
import PHPImg from "../images/php-original.svg";
import ReactImg from "../images/react-original-wordmark.svg";
import TypeScriptImg from "../images/typescript-original.svg";

const useSkills = () => {
  return [
    {
      src: DotNetImg,
      alt: "ASP.NET",
    },
    {
      src: AzureImg,
      alt: "Azure",
    },
    {
      src: BitbucketImg,
      alt: "Bitbucket",
    },
    {
      src: BootstrapImg,
      alt: "Bootstrap",
    },
    {
      src: CImg,
      alt: "C programming",
    },
    {
      src: CSharpImg,
      alt: "C# programming",
    },
    {
      src: CPlusPlusImg,
      alt: "C++ programming",
    },
    {
      src: CSS3Img,
      alt: "CSS3",
    },
    {
      src: DockerImg,
      alt: "Docker",
    },
    {
      src: ExpressImg,
      alt: "Express.js",
    },
    {
      src: GitImg,
      alt: "Git",
    },
    {
      src: GitHubImg,
      alt: "GitHub",
    },
    {
      src: GraphQLImg,
      alt: "GraphQL",
    },
    {
      src: JQueryImg,
      alt: "Jquery",
    },
    {
      src: JiraImg,
      alt: "Jira",
    },
    {
      src: MaterialUIImg,
      alt: "Material UI",
    },
    {
      src: MongoDBImg,
      alt: "MongoDB",
    },
    {
      src: MySQLImg,
      alt: "MySQL",
    },
    {
      src: MSSQLServerImg,
      alt: "Microsoft SQL Server",
    },
    {
      src: NodeJSImg,
      alt: "Node.js",
    },
    {
      src: PostgreSQLImg,
      alt: "PostgreSQL",
    },
    {
      src: PythonImg,
      alt: "Python",
    },
    {
      src: PHPImg,
      alt: "PHP",
    },
    {
      src: ReactImg,
      alt: "React.js",
    },
    {
      src: TypeScriptImg,
      alt: "TypeScript",
    },
  ];
};

export default useSkills;
