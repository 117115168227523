import { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Image from "mui-image";
import useStyles from "./About.style";
import Button from "@mui/material/Button";
import useSkills from "./skills";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const About = ({ scrollBeyondThreshold }) => {
  const [scrollCheck, setScrollCheck] = useState(false);
  const classes = useStyles();
  const skills = useSkills();

  return (
    <>
      <Container maxWidth="lg" align="center" className={classes.container}>
        <Typography
          variant="h3"
          color="textPrimary"
          fontWeight="fontWeightBold"
          id="About"
        >
          <Box sx={{ paddingTop: 5 }}>About Me</Box>
        </Typography>

        <div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 14 }}
          >
            <Grid xs={12} sm={6}>
              <Typography
                variant="h4"
                color="textSecondary"
                fontWeight="500"
                align="left"
              >
                <Box sx={{ marginTop: 3 }}>Get to Know me</Box>
              </Typography>
              <Typography variant="body1" className={classes.content}>
                <Box sx={{ textAlign: "left", m: 0, marginTop: 3 }}>
                  Hi, I’m Basit, a Software Developer with 2 years of experience
                  in Programming with TypeScript, React, and Node. I specialize
                  in both frontend and backend web development and have worked
                  on projects ranging from a mobile repair management app to a
                  building and properties assessment app.
                </Box>
              </Typography>
              <Typography variant="body1" className={classes.content}>
                <Box sx={{ textAlign: "left", m: 0, marginTop: 2 }}>
                  I am passionate about creating innovative solutions that solve
                  real-world problems. My goal is to help businesses and
                  individuals achieve their goals through technology.
                </Box>
              </Typography>
              <Typography variant="body1" className={classes.content}>
                <Box
                  sx={{
                    textAlign: "left",
                    m: 0,
                    marginTop: 2,
                    marginBottom: 5,
                  }}
                >
                  Check out some of my recent projects below and feel free to
                  get in touch if you have any questions or would like to work
                  together.
                </Box>
              </Typography>
            </Grid>
            <Grid xs={12} sm={6}>
              <Typography
                variant="h4"
                color="textSecondary"
                fontWeight="500"
                align="left"
              >
                <Box sx={{ marginTop: 3 }}>My Skills</Box>
              </Typography>
              <Item
                sx={{ boxShadow: "none", m: 0, marginTop: 3 }}
                className={classes.skills}
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {skills.map((skill, i) => (
                    <Grid key={`${i}-${skill}`} xs={4} sm={4} md={3} lg={2}>
                      <Tooltip title={skill.alt} placement="top" arrow>
                        <Button>
                          <Image
                            src={skill.src}
                            alt={skill.alt}
                            width={60}
                            height={60}
                            duration={400}
                            easing="ease-in-out"
                            showLoading
                          />
                        </Button>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default About;
