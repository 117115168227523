import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useStyles from "./Header.style";
import TerminalIcon from "@mui/icons-material/Terminal";

const pages = ["Home", "About", "Projects", "Contact"];

const Header = ({ scrollBeyondThreshold }) => {
  const classes = useStyles();

  const handleCloseNavMenu = (e) => {
    console.log("Clicked!");
    console.log(e.target.innerHTML);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.container}>
        <Container maxWidth="xl">
          <Toolbar className={classes.icon}>
            <TerminalIcon
              fontSize="large"
              style={{ fill: scrollBeyondThreshold ? "#253858" : "#fff" }}
            />
            {/* <Typography variant="h6">Basit Bada</Typography> */}

            {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "right",
                display: { xs: "none", md: "flex" },
                textTransform: "none",
                // color: 'green',
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  href={`#${page}`}
                  sx={{
                    my: 2,
                    // color: 'textSecondary',
                    display: "block",
                    textTransform: "none",
                  }}
                >
                  <Typography
                    color="textSecondary"
                    fontWeight="fontWeightMedium"
                  >
                    {page}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
