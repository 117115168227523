import { useEffect, useState } from "react";
import Image from "mui-image";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useStyles from "./Contact.style";
import Checkmark from "../images/checkmark.svg";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [fullNameError, setFullNameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [messageError, setMessageError] = useState(true);
  const classes = useStyles();

  const handleFullNameChange = (e) => {
    const reg = new RegExp("[a-z]");

    setFullName(e.target.value);
    setFullNameError(reg.test(e.target.value));
  };

  const handleEmailChange = (e) => {
    const reg = /^[^\.\s][\w\-]+(\.[\w\-]+)*@([\w-]+\.)+[\w-]{2,}$/gm;

    setEmail(e.target.value);
    setEmailError(reg.test(e.target.value));
  };

  const handleMessageChange = (e) => {
    const reg = new RegExp("[a-z]");
    setMessage(e.target.value);
    setMessageError(reg.test(e.target.value));
  };

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...{ name: fullName, email: email, message: message },
      }),
    })
      .then((res) => {
        console.log(res);

        if (res.ok) {
          setSuccess(true);
          setFullName("");
          setFullNameError(true);
          setEmail("");
          setEmailError(true);
          setMessage("");
          setMessageError(true);
        }
      })
      .catch((error) => alert(error));

    e.preventDefault();
  };

  return (
    <>
      <Container maxWidth="md">
        <div>
          <Typography
            variant="h3"
            color="textPrimary"
            fontWeight="fontWeightBold"
            id="Contact"
          >
            <Box sx={{ marginTop: 10, textAlign: "center", marginBottom: 5 }}>
              Message Me
            </Box>
          </Typography>
        </div>

        <Paper elevation={5} sx={{ height: "100%" }}>
          {success && (
            <>
              <Box
                sx={{
                  display: "inline-block",
                  color: "green",
                  margin: "20px 10px 0px 20px",
                }}
              >
                <Image src={Checkmark} alt="" width={18} height={18} />
              </Box>{" "}
              <Typography
                variant="body1"
                sx={{ display: "inline-block", color: "green" }}
              >
                {" "}
                Thank you for your message!
              </Typography>
            </>
          )}
          <Card>
            <Box
              component="form"
              name="contact"
              method="POST"
              action="/#contact/?success=true"
              data-netlify="true"
              onSubmit={handleSubmit}
              sx={{
                // "& > :not(style)": { m: 2, width: "100%" },
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                "& > *": {
                  //   margin: "0.5rem 0",
                },
                m: 3,
              }}
              noValidate
              autoComplete="off"
            >
              <input type="hidden" name="form-name" value="contact" />
              <TextField
                id="outlined-basic"
                label="full name"
                name="name"
                value={fullName}
                variant="outlined"
                onChange={(e) => handleFullNameChange(e)}
                error={!fullNameError}
                required={true}
              />
              <TextField
                id="outlined-basic"
                label="email"
                name="email"
                value={email}
                variant="outlined"
                onChange={(e) => handleEmailChange(e)}
                error={!emailError}
                required={true}
              />
              <TextField
                multiline
                id="outlined-basic"
                label="message"
                name="message"
                value={message}
                rows={8}
                variant="outlined"
                error={!messageError}
                onChange={(e) => handleMessageChange(e)}
                required={true}
              />

              <Button
                type="submit"
                variant="contained"
                // style={{ backgroundColor: "#260A49" }}
                color="secondary"
                // onClick={(e) => e.preventDefault()}
              >
                Submit
              </Button>
            </Box>
          </Card>
        </Paper>
      </Container>
      <br />
    </>
  );
};

export default Contact;
